import styled from "styled-components";
import { EditWrite, PhoneCall } from "react-basicons";
import { Colors } from "../styles/Theme";
import { SidebarItem } from "./SidebarItem";
import { sidebarData } from "./sidebarData";
import { logout } from "../redux/actions/authActions";
import { GoSignOut } from "react-icons/go";
import { useAppDispatch } from "../redux/hooks";
import { AiOutlineQuestionCircle } from "react-icons/ai";
export function Sidebar() {
  const dispatch = useAppDispatch();
  return (
    <Container className="hidden md:block shadow-[0px_6px_6px_0px_#00000026]">
      <ItemWrapper>
        {sidebarData.map((item) => (
          <SidebarItem key={item.path} {...item} />
        ))}

        <Item>
          <a href="https://docs.altum.ai" target="_blank" rel="noreferrer">
            <EditWrite size={23} />
            <span>Documentatie</span>
          </a>
        </Item>

        <Item>
          <a
            href="https://docs.altum.ai/mopsus-data-platform"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiOutlineQuestionCircle size={23} />
            <span>FAQ</span>
          </a>
        </Item>
        <Item>
          <PhoneCall size={23} />
          <span>+31792340990</span>
        </Item>
        <Item onClick={() => dispatch(logout())}>
          <GoSignOut size={23} className="text-red-400" />
          <span className="text-red-400">Uitloggen</span>
        </Item>
      </ItemWrapper>
    </Container>
  );
}
const Container = styled.div`
  flex: 1;
  background-color: white;
  min-height: 100vh;
  width: 100%;
  position: sticky;
  top: 0;
`;

const ItemWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: left;
  cursor: pointer;
  padding: 10px;
  position: sticky;
  top: 0;

  .item-phone {
    display: flex;
    border-radius: 10px;
    padding: 10px;
    align-items: center;
    gap: 10px;

    @media (max-width: 960px) {
      span {
        display: none;
      }
    }
  }
`;

export const Item = styled.li`
  display: flex;
  border-radius: 10px;
  padding: 10px;
  align-items: center;
  gap: 10px;

  @media (max-width: 960px) {
    span {
      display: none;
    }
  }

  a {
    color: black;
    text-align: center;
    display: flex;
    align-items: center;
    span {
      margin-left: 10px;
    }
  }

  .active {
    border-left: 2px solid ${Colors.main.green};
    background-color: ${Colors.main.lightGreen};
    padding: 5px;
    border-radius: 5px;
  }
`;
