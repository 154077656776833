import React, { FC } from "react";
import Table from "../../../../components/Table";
import CardContainer from "../../components/CardContainer";

type Props = {
  logs: { api_name: string; requestTime: string; status: string }[];
};

const APILogs: FC<Props> = ({ logs }) => {
  return (
    <CardContainer title={"API logboek"}>
      <div className="mt-4 w-full md:w-full">
        <Table headers={["API", "TIJDSTIP", "Status"]} tableItems={logs} />
      </div>
    </CardContainer>
  );
};

export default APILogs;
