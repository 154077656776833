import {
  ChangeEvent,
  FC,
  FocusEventHandler,
  MouseEventHandler,
  useState,
} from "react";
import RadioInput from "../../../components/RadioInput";
import Button from "../../../components/Button";
import TextInput from "../../../components/TextInput";
import SelectInput from "../../../components/SelectInput";
import { OnboardingQuestion } from "../../../@types";

type Props = {
  step: number;
  currentQuestion: OnboardingQuestion;
  loading: boolean;
  onboardingData: {
    question_id: string;
    responses: string[];
  }[];
  setOnboardingData: React.Dispatch<
    React.SetStateAction<
      {
        question_id: string;
        responses: string[];
      }[]
    >
  >;
  multiSelect: string[];
  handleChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  handleClick?: MouseEventHandler;
  handleFocus?: FocusEventHandler;
};

const OnboardingField: FC<Props> = ({
  step,
  currentQuestion,
  loading,
  multiSelect,
  onboardingData,
  setOnboardingData,
  handleChange,
  handleClick,
}) => {
  const [otherInput, setOtherInput] = useState(false);
  const [active, setActive] = useState("");
  const currentlySelected = onboardingData.find(
    (data) => data.question_id === currentQuestion.question_id,
  );
  const orderedOptions = [...currentQuestion.options]?.sort(
    (a, b) => a.rank - b.rank,
  );
  return (
    <>
      {step === 1 || step === 5 ? (
        orderedOptions.map((option) => (
          <RadioInput
            key={option.rank}
            name={option.option}
            value={option.option}
            label={option.option}
            selected={option.option === currentlySelected?.responses[0]}
            onChange={handleChange}
            className={"gap-4 border rounded-md mb-2 md:rounded-xl p-4 mb:mb-4"}
          />
        ))
      ) : step === 2 ? (
        <SelectInput
          onChange={handleChange}
          label={"Kies er een"}
          options={orderedOptions.map((option) => ({
            value: option.option,
            label: option.option,
          }))}
        />
      ) : step === 3 ? (
        <div className="flex flex-col gap-4">
          <div className="flex flex-wrap justify-center gap-2 md:gap-4 ">
            {orderedOptions.map((option) => (
              <Button
                key={option.rank}
                className={`${
                  active === option.option ? "bg-secondary" : "bg-white"
                } mt-2 px-2 py-2 min-w-[123px] text-xs text-gray-dark`}
                size="xs"
                onClick={() => {
                  if (option.option === "Anders") {
                    setOtherInput(true);
                  } else {
                    setOnboardingData((prev) => {
                      const existingEntryIndex = prev.findIndex(
                        (item) =>
                          item.question_id === currentQuestion.question_id,
                      );

                      if (existingEntryIndex !== -1) {
                        const updatedData = [...prev];
                        updatedData[existingEntryIndex] = {
                          ...updatedData[existingEntryIndex],
                          responses: [option.option],
                        };
                        return updatedData;
                      } else {
                        return [
                          ...prev,
                          {
                            question_id: currentQuestion.question_id,
                            responses: [option.option],
                          },
                        ];
                      }
                    });
                  }
                  setActive(option.option);
                }}
              >
                {option.option}
              </Button>
            ))}
          </div>
          {otherInput && active === "Anders" && (
            <TextInput
              type={""}
              name={"other"}
              placeholder={"Gelieve te specificeren"}
              onChange={handleChange}
            />
          )}
        </div>
      ) : (
        orderedOptions.map((option) => (
          <RadioInput
            key={option.rank}
            name={option.option}
            value={option.option}
            label={option.option}
            selected={multiSelect.includes(option.option)}
            className="gap-4 border rounded-md mb-2 md:rounded-xl p-4 mb:mb-4"
            onChange={handleChange}
          />
        ))
      )}
      <Button
        className="bg-primary w-full"
        onClick={handleClick}
        isProcessing={loading}
      >
        Doorgaan
      </Button>
    </>
  );
};

export default OnboardingField;
