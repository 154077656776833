import { Select } from "flowbite-react";
import React, { ChangeEvent, FC } from "react";

type Option = {
  value: string;
  label: string;
};

type Props = {
  label?: string;
  name?: string;
  className?: string;
  options: Option[];
  value?: string;
  onChange?: (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void;
};

const SelectInput: FC<Props> = ({
  options,
  label,
  name,
  className,
  value,
  onChange,
}) => {
  return (
    <Select
      className={className}
      name={name}
      value={value}
      theme={{
        field: {
          select: {
            base: "focus:border-0 focus:ring-0 border-primary focus:outline-primary outline-primary border-gray-light w-full",
            colors: {
              gray: "gray-light",
              primary: "primary",
            },
          },
        },
      }}
      onChange={onChange}
    >
      {!!label && <option value="">{label}</option>}
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};

export default SelectInput;
