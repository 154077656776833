import React, { useState, useEffect } from "react";

interface ImagePreviewProps {
  images: File[];
  onRemove: (index: number) => void;
}

export const ImagePreview: React.FC<ImagePreviewProps> = ({
  images,
  onRemove,
}) => {
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);

  useEffect(() => {
    const newPreviews: string[] = [];

    const createPreviews = async () => {
      for (const image of images) {
        try {
          const preview = await createImagePreview(image);
          newPreviews.push(preview);
        } catch (error) {
          console.error("Error creating preview for image:", image.name, error);
          newPreviews.push("");
        }
      }
      setImagePreviews(newPreviews);
    };

    createPreviews();

    return () => {
      // Cleanup function
      newPreviews.forEach((preview) => {
        if (preview.startsWith("blob:")) {
          URL.revokeObjectURL(preview);
        }
      });
    };
  }, [images]); // Remove imagePreviews from the dependency array

  const createImagePreview = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target?.result as string);
      reader.onerror = (e) => reject(e);
      reader.readAsDataURL(file);
    });
  };

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
      {imagePreviews.map((preview, index) => (
        <div key={index} className="relative">
          {preview ? (
            <img
              src={preview}
              alt={images[index]?.name || `Image ${index + 1}`}
              className="w-full h-32 object-cover rounded"
            />
          ) : (
            <div className="w-full h-32 bg-gray-200 flex items-center justify-center rounded">
              Failed to load preview
            </div>
          )}
          <button
            onClick={() => onRemove(index)}
            className="absolute top-1 right-1 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
            aria-label={`Remove image ${index + 1}`}
          >
            ×
          </button>
        </div>
      ))}
    </div>
  );
};
