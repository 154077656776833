import React, { useCallback, useState } from "react";
import { useDropzone, FileRejection, DropEvent } from "react-dropzone";
import { ImagePreview } from "./ImagePreview";
import { LuImagePlus } from "react-icons/lu";
import Subtitle from "./Subtitle";

interface ImageUploaderProps {
  images: File[];
  onImagesSelected: (files: File[]) => void;
  onImageRemove: (index: number) => void;
}

export const ImageUploader: React.FC<ImageUploaderProps> = ({
  images,
  onImagesSelected,
  onImageRemove,
}) => {
  const [error, setError] = useState<string | null>(null);

  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent,
    ) => {
      if (fileRejections.length > 0) {
        setError(
          "Invalid file format. Please use .jpg, .jpeg, .png, or .gif files.",
        );
      } else {
        setError(null);
        onImagesSelected([...acceptedFiles]);
      }
    },
    [onImagesSelected],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: [".jpg", ".jpeg", ".png", ".gif"],
    multiple: true,
  });

  return (
    <div>
      <div
        {...getRootProps()}
        className="border-2 border-dashed border-gray-300 p-6 rounded-lg text-center"
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the images here ...</p>
        ) : (
          <div className="flex flex-col items-center">
            <LuImagePlus className="w-10 h-10 text-gray-500" />
            <Subtitle className="text-base text-purple-600">
              Sleep een afbeelding of blader
            </Subtitle>
            <p className="text-sm text-gray-500">
              Ondersteunde formaten: .jpg, .jpeg, .png, .gif
            </p>
          </div>
        )}
      </div>
      {error && <p className="text-red-500 mt-2">{error}</p>}
      <ImagePreview images={images} onRemove={onImageRemove} />
    </div>
  );
};
