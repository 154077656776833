import { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import styled from "styled-components";
import Lottie from "lottie-react";
import { Button } from "../styles/styled";
import success from "../assets/success.json";
import { device } from "../styles/Theme";

function SuccessPage() {
  const [timer, setTimer] = useState(5);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  if (timer === 0) {
    return <Redirect to="/" />;
  }
  return (
    <PaymentContainer>
      <PaymentFlex>
        <Lottie animationData={success} />
        <div className="payment-column">
          <div className="title">Abonnement gewijzigd</div>
          <div className="text">
            Het abonnement is direct gewijzigd. Je kunt meteen aan de slag.
          </div>
          <Button>
            <Link to="/dashboard/startpagina" className="redirect-link">
              Ga direct naar het Dashboard
            </Link>
          </Button>
        </div>
      </PaymentFlex>
    </PaymentContainer>
  );
}

export default SuccessPage;
const PaymentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16rem;
  @media ${device.tablet} {
    padding: 4rem;
  }
`;

const PaymentFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: center;
  align-items: center;

  .payment-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .title {
    font-size: 20px;
    font-weight: 500;
  }

  .text {
    font-size: 14px;
  }

  a {
    color: white;
  }
`;
