import React from "react";
import Input from "../../../components/TextInput";
import { PropertyDetails } from "../../../@types";

interface PropertyDetailsFormProps {
  propertyDetails: PropertyDetails;
  onPropertyDetailsChange: (details: PropertyDetails) => void;
}

export const PropertyDetailsForm: React.FC<PropertyDetailsFormProps> = ({
  propertyDetails,
  onPropertyDetailsChange,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    onPropertyDetailsChange({ ...propertyDetails, [name]: value });
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <Input
        type="text"
        name="yearOfConstruction"
        value={propertyDetails.yearOfConstruction}
        onChange={handleInputChange}
        placeholder="Bouwjaar"
        size="md"
        className="w-full"
      />
      <Input
        type="text"
        name="location"
        value={propertyDetails.location}
        onChange={handleInputChange}
        placeholder="Locatie"
        size="md"
        className="w-full"
      />
      <Input
        type="text"
        name="area"
        value={propertyDetails.area}
        onChange={handleInputChange}
        placeholder="Oppervlakte (m²)"
        size="md"
        className="w-full"
      />
      <Input
        type="text"
        name="propertyType"
        value={propertyDetails.propertyType}
        onChange={handleInputChange}
        placeholder="Woningtype"
        size="md"
        className="w-full"
      />
      <Input
        type="text"
        name="facilities"
        value={propertyDetails.facilities}
        onChange={handleInputChange}
        placeholder="Voorzieningen"
        size="md"
        className="w-full"
      />
    </div>
  );
};
