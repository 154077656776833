import { CodeTechDev, HomeHouse, UserAccountProfile } from "react-basicons";
import { BsCurrencyEuro, BsStack } from "react-icons/bs";
import { FiLayers } from "react-icons/fi";
import { HiOutlineViewGrid } from "react-icons/hi";
import { IoNavigateCircleOutline, IoSparklesOutline } from "react-icons/io5";
import { IoAnalytics } from "react-icons/io5";

export const sidebarData = [
  {
    name: "Aan de slag",
    path: "/dashboard/aandeslag",
    Icon: IoNavigateCircleOutline,
  },
  {
    name: "Vastgoed AI",
    path: "/property-description",
    Icon: IoSparklesOutline,
    className:
      "text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-green-400", // Tailwind classes for gradient text from purple to green
  },
  {
    name: "Dashboard",
    path: "/dashboard/startpagina",
    Icon: HomeHouse,
  },
  {
    name: "Producten",
    path: "/dashboard/products",
    Icon: HiOutlineViewGrid,
  },

  {
    name: "Account",
    path: "/dashboard/account",
    Icon: UserAccountProfile,
  },
  {
    name: "Analytics",
    path: "/dashboard/analytics",
    Icon: IoAnalytics,
  },

  {
    name: "Abonnement",
    path: "/dashboard/abonnement",
    Icon: BsCurrencyEuro,
  },
  {
    name: "API keys",
    path: "/apikeys",
    Icon: CodeTechDev,
  },
];
