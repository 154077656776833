import React, { Dispatch, FC, SetStateAction, useState } from "react";
import Text from "../../../../components/Text";
import Modal from "../../../../components/Modal";
import SelectInput from "../../../../components/SelectInput";
import Subtitle from "../../../../components/Subtitle";
import Button from "../../../../components/Button";
import { renderSubscriptionDetails } from "../../../../helpers/renderSubscrptionDetails";
import { getCredits, getPrice } from "../../../../helpers/stripeHelper";
import convertToEuFormat from "../../../../helpers/convertToEuFormat";
import { IoMdArrowBack } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";
import { CheckoutForm } from "../../../../components/StripeNotifierPages/CheckoutForm";
import {
  createSubscription,
  updateSubscription,
} from "../../../../redux/actions/subscriptionActions";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";

type Props = {
  selectedPlan: string;
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
};
const pages = ["Abonnementsoverzicht", "Betaalmethode"];

const TransactionSummary: FC<Props> = ({
  selectedPlan,
  openModal,
  setOpenModal,
}) => {
  const history = useHistory();
  const { portal, auth } = useAppSelector((state) => state);
  const [page, setPage] = useState(1);
  const price = getPrice(selectedPlan);
  const percentage = (percent: number, amount: number) =>
    (percent / 100) * amount;

  const header = (
    <div className="flex gap-4 ml-4">
      {pages.map((pageName, idx) => (
        <div
          className={`flex gap-2 items-center ${
            idx + 1 < pages.length ? "after:content-['>']" : ""
          }`}
          key={idx}
        >
          <span
            className={`text-xs ${
              idx + 1 <= page
                ? "bg-primary  w-6 h-6 text-center px-1.5 py-1 rounded-full"
                : "text-gray-light"
            } `}
          >
            {page > idx + 1 ? <FaCheck className="mt-px" /> : idx + 1}
          </span>

          <Text
            className={` cursor-pointer ${
              idx + 1 <= page ? "" : "text-gray-light"
            } `}
            onClick={() => setPage(idx + 1)}
          >
            {pageName}
          </Text>
        </div>
      ))}
    </div>
  );
  return (
    <Modal header={header} trigger={openModal} setTrigger={setOpenModal}>
      {page === 1 && Overview(selectedPlan, price, percentage, setPage, page)}
      {page === 2 && (
        <>
          {auth?.user && (
            <CheckoutForm
              plan={selectedPlan}
              user={auth?.user}
              history={history}
              createSubscription={createSubscription}
              updateSubscription={updateSubscription}
              status={
                selectedPlan?.includes("Kadaster")
                  ? portal.transactionSubscriptionStatus
                  : portal.subscriptionStatus
              }
            />
          )}
        </>
      )}
    </Modal>
  );
};

export default TransactionSummary;
function Overview(
  selectedPlan: string,
  price: number,
  percentage: (percent: number, amount: number) => number,
  setPage: React.Dispatch<React.SetStateAction<number>>,
  page: number,
) {
  return (
    <div className="flex gap-4 py-4 px-2 bg-[#f2f2f2]">
      <div className="w-full">
        <SelectInput options={[{ value: selectedPlan, label: selectedPlan }]} />
        <Text className="text-gray-light mt-4">
          {renderSubscriptionDetails(selectedPlan)}
        </Text>
      </div>
      <div className="w-full bg-white p-4 rounded-md flex flex-col justify-between min-h-[342px]">
        <div className="flex flex-col gap-4">
          <Subtitle className="text-base">Abonnementsoverzicht</Subtitle>
          <div className="flex items-center justify-between">
            <Text>Maandelijks bedrag:</Text>
            <Subtitle className="text-base">€{price}</Subtitle>
          </div>
          <div className="flex items-center justify-between">
            <Text>Totaal (incl. 21% BTW):</Text>
            <Subtitle className="text-base">
              €{convertToEuFormat(percentage(21, price) + price)}
            </Subtitle>
          </div>
          <div className="flex items-center justify-between">
            <Text>Aantal credits inbegrepen:</Text>
            <Subtitle className="text-base">
              {convertToEuFormat(getCredits(selectedPlan))}
            </Subtitle>
          </div>
        </div>
        <Button
          className="bg-primary w-full h-[43px]"
          onClick={() => setPage(page + 1)}
        >
          Doorgaan met betalen
        </Button>
      </div>
    </div>
  );
}
