import React from "react";
import { ConfigureOutput } from "../../../@types";
import SelectInput from "../../../components/SelectInput";
import Input from "../../../components/TextInput";

interface ConfigureOutputFormProps {
  configureOutput: ConfigureOutput;
  onConfigureOutputChange: (config: ConfigureOutput) => void;
}

export const ConfigureOutputForm: React.FC<ConfigureOutputFormProps> = ({
  configureOutput,
  onConfigureOutputChange,
}) => {
  const handleInputChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    onConfigureOutputChange({ ...configureOutput, [name]: value });
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <SelectInput
        name="tone"
        value={configureOutput.tone}
        onChange={handleInputChange}
        options={[
          { value: "", label: "Kies toon" },
          { value: "professional", label: "Professioneel" },
          { value: "friendly", label: "Vriendelijk" },
          { value: "enthusiastic", label: "Enthousiast" },
        ]}
      />
      <Input
        type="text"
        name="targetAudience"
        value={configureOutput.targetAudience}
        onChange={handleInputChange}
        placeholder="Doelgroep"
        size="md"
      />
      <SelectInput
        name="languagePreference"
        value={configureOutput.languagePreference}
        onChange={handleInputChange}
        options={[
          { value: "", label: "Kies taal" },
          { value: "en", label: "Engels" },
          { value: "nl", label: "Nederlands" },
        ]}
      />
      <SelectInput
        name="descriptionLength"
        value={configureOutput.descriptionLength}
        onChange={handleInputChange}
        options={[
          { value: "", label: "Kies beschrijvingslengte" },
          { value: "short", label: "Kort" },
          { value: "medium", label: "Gemiddeld" },
          { value: "long", label: "Lang" },
        ]}
      />
    </div>
  );
};
