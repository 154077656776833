import axios from "axios";
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

interface ChatObject {
  id: string;
  description: string;
  created_at: string;
}
interface IPropertyContext {
  chatHistory: ChatObject[];
  setChatHistory: React.Dispatch<React.SetStateAction<ChatObject[]>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pagination:
    | {
        currentPage: number;
        pageSize: number;
        totalCount: number;
        totalPages: number;
      }
    | undefined;
}
const PropertyContext = createContext<IPropertyContext | undefined>(undefined);

export const fetchChatHistory = async (page: number) => {
  const history = await axios.get<{
    chatHistory: ChatObject[];
    pagination: any;
  }>(`/api/v1/property-description/chat-history?page=${page}`);
  return {
    chatHistory: history.data.chatHistory,
    pagination: history.data.pagination,
  };
};

const PropertyProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [history, setHistory] = useState<ChatObject[]>([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState();

  useEffect(() => {
    fetchChatHistory(page)
      .then((res) => {
        setHistory(res.chatHistory);
        setPagination(res.pagination);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page]);

  return (
    <PropertyContext.Provider
      value={{
        chatHistory: history,
        setChatHistory: setHistory,
        page,
        setPage,
        pagination,
      }}
    >
      {children}
    </PropertyContext.Provider>
  );
};

const usePropertyContext = () => {
  const context = useContext(PropertyContext);

  if (!context) {
    throw new Error("Not a property context");
  }
  return context;
};

export { PropertyProvider, usePropertyContext };
